<template>
    <div class="index-page">
        <div
            class="index-page__main"
            :style="{
                backgroundImage: `
          url('${getImage(heroImageUrl, 1050, 70)}'),
          url('${getImage(heroImageUrl, 800, 50)}'),
          url('${getImage(heroImageUrl, 300, 50)}')
        `,
            }"
        >
            <div
                class="index-countries"
                :style="{
                    backgroundImage: `url('${getImage(heroMapUrl, 800, 50)}')`,
                }"
            >
                <div class="index-countries__logo">
                    <NuxtImg
                        v-if="logoUrl"
                        :src="logoUrl"
                        title="Looking4"
                        alt="Looking4 Logo"
                        format="webp"
                        loading="eager"
                    />
                </div>
                <ul class="index-countries__list">
                    <li v-for="country in blok.countries" :key="country._uid">
                        <Component :is="country.component" :blok="country" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4IndexCountries } from '~/types/components/L4IndexCountries';

const { blok } = defineProps<{ blok: IL4IndexCountries }>();

const heroImageUrl = computed(() => blok.BackgroundImage.filename || 'none');
const heroMapUrl = computed(() => blok.HeroMapImage.filename || 'none');
const logoUrl = computed(() => blok.LogoImage.filename || '');
</script>

<style lang="postcss" scoped>
@import '~/assets/css/logos.css';
.index-page__main {
    @apply flex grow items-center justify-center bg-cover bg-[0%_22%] min-h-[calc(100vh_-_273px)] p-16;
}
.index-countries {
    @apply bg-white p-4 md:p-8 bg-no-repeat;
    background-position: 50% 120px;
    background-size: 100%;
    &__logo {
        @apply flex justify-center;
        img {
            @apply w-full sm:w-1/2;
        }
    }
    &__list {
        @apply grid grid-cols-2 md:grid-cols-3 grid-flow-row md:pl-20 pt-4;
    }
}
</style>
